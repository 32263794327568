import React from 'react';

const HanchLogoGradientSVG = `

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" baseProfile="tiny" version="1.2" viewBox="0 0 100 40.7">
  <defs>
    <linearGradient id="Orange_Yellow" data-name="Orange, Yellow" x1="17.1" y1="40.7" x2="17.1" y2="1.6" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff33b"/>
      <stop offset="0" stop-color="#fee62d"/>
      <stop offset=".1" stop-color="#fdd51b"/>
      <stop offset=".2" stop-color="#fdca0f"/>
      <stop offset=".3" stop-color="#fdc70c"/>
      <stop offset=".7" stop-color="#f3903f"/>
      <stop offset=".9" stop-color="#ed683c"/>
      <stop offset="1" stop-color="#e93e3a"/>
    </linearGradient>
    <linearGradient id="Orange_Yellow-2" data-name="Orange, Yellow" x1="44.1" y1="37.6" x2="44.1" y2="12.1" xlink:href="#Orange_Yellow"/>
    <linearGradient id="Orange_Yellow-3" data-name="Orange, Yellow" x1="60.6" y1="37.6" x2="60.6" y2="11.2" xlink:href="#Orange_Yellow"/>
    <linearGradient id="Orange_Yellow-4" data-name="Orange, Yellow" x1="75.1" y1="37.6" x2="75.1" y2="12.1" xlink:href="#Orange_Yellow"/>
    <linearGradient id="Orange_Yellow-5" data-name="Orange, Yellow" x1="90.6" y1="37.6" x2="90.6" y2="0" xlink:href="#Orange_Yellow"/>
  </defs>
  <path d="M33.5,35.9c-.5.9-1.1,1.6-1.8,2.1s-1.6,1-2.6,1.6l-1.2.6c-.6.3-1.2.5-1.7.5s-.8,0-1.2-.2c-.4-.1-1-.4-1.8-.7l-1-.4c-.8-.4-1.7-.6-2.7-.6s-1.1,0-1.6.2c-.5.1-.9.2-1.2.3-.3.1-.5.2-.6.2.6-.8,1.2-1.7,1.9-2.8v-13.8c-.2-.5-.8-1.2-1.7-2.2-.9-1-1.5-1.6-1.9-1.8,0,.6,0,1.1,0,1.6v12.2c0,1.2-.2,2.2-.6,2.8-.4.7-.9,1.2-1.6,1.5-.7.3-1.6.6-2.7.9.9-.8,1.3-3.6,1.3-8.4s0-3.4-.1-5c0-1.6-.2-2.6-.2-3.1-.3,0-.9,0-1.5,0-1.5,0-2.8.3-4,1-1.1.6-2,1.4-2.6,2.4-.6,1-.9,2-.9,3.1s.2,1.7.6,2.4c.4.7,1,1.3,1.8,1.6.8.4,1.5.6,2.2.6s1,0,1.4-.3c.4-.2.7-.4.9-.6.2-.2.3-.3.3-.4-.2.4-.5.8-1.2,1.1-.6.3-1.4.4-2.2.4s-1.5-.2-2.3-.5c-.7-.3-1.3-.8-1.8-1.6-.9-1.3-1.3-2.9-1.3-4.9s.3-2.8.8-4.1c.5-1.3,1.3-2.3,2.3-3,.7-.4,1.7-.7,2.8-.9,1.2-.1,2.7-.2,4.5-.2v-8.3h-5.3c-1,0-1.8.4-2.4,1.1-.6.7-.9,1.5-.9,2.5s.3,1.7.8,2.3c-.6-.2-1.2-.7-1.6-1.6-.4-.8-.6-1.7-.6-2.8,0-1.6.4-2.9,1.3-4.1.9-1.2,2.1-1.7,3.9-1.7s3.2-.1,4.8-.4c1.5-.2,3.3-.6,5.3-1,.7-.2,1.4-.3,2.2-.5v-1h.4v1h0,0v13.8c.2.4.6,1,1.3,1.6.7.6,1.3,1.1,1.7,1.3-.6.2-1.3.6-1.9,1-.6.4-1,1.2-1.1,2.1v13.1c1.1-1.7,2-3.5,2.8-5.2.8-1.7,1.2-3.2,1.2-4.3V8.1c0-1-.6-1.4-1.9-1.4s-.5,0-.6,0c-.2,0-.3,0-.3,0L27.6,1.6c0,0-.2.3-.4.6-.2.3-.4.8-.6,1.4-.2.6-.3,1.3-.3,2v16.8c0,1,.6,1.5,1.7,1.5s.4,0,.6,0c.2,0,.3,0,.3,0l-2.6,2.8-6.9,8.6c.9-.7,1.8-1,2.8-1s1.2.1,1.8.3c.6.2,1.4.5,2.4,1l1.9.8c.7.3,1.4.4,1.9.4,1.1,0,1.9-.4,2.5-1.2.6-.8.9-1.7.9-2.8s-.2-1.4-.5-1.7-.7-.6-1.2-.8c-.5-.2-.8-.4-.9-.5-.2-.2-.1-.3.1-.6.2-.2.6-.3.9-.3.5,0,1,.3,1.4.8.4.5.6,1.4.7,2.7,0,1.3-.2,2.5-.7,3.4ZM17.8,16.7V3.2c-1.1.5-2,1.5-2.6,3s-.9,3.2-.9,5.2v7.6c1.2,0,2.4-.8,3.5-2.3Z" fill="url(#Orange_Yellow)" stroke-width="0"/>
  <path d="M41.2,22v.6l-1.7.9v8.2c0,.6.4,1.1,1.2,1.5.8.4,1.6.6,2.4.6s1-.1,1.2-.4l1.8-1.9v-14.1c-1,0-2.7,0-5.1,0-2.5,0-3.6,0-3.3,0,1.9.7,3.3,1.7,4.4,2.8,1.1,1.1,1.9,2.1,2.3,3.1s.7,1.6.7,2h0c0,.4-.2.6-.6.6s-.2,0-.3-.1c-.1,0-.2-.2-.2-.3-.2-1.5-.9-2.9-1.9-4.2-1.1-1.3-2.3-2.2-3.6-2.9s-2.3-1-3.1-1l4.8-5.2c0,0,0,.2.1.5,0,.2.2.5.5.7.3.2.7.3,1.3.3h6c.4,0,.9,0,1.4-.3.5-.2,1-.4,1.3-.6.4-.2.6-.4.7-.4-.4,1.1-.7,2.2-.9,3.3-.2,1.1-.3,2-.3,2.8v10.6c0,1.4.2,2.5.6,3.3.4.8.9,1.3,1.5,1.5l1-1.2.4.3-4.1,4.7c-1.1-.9-1.9-1.8-2.5-2.7-.6-.9-.9-1.8-1.1-2.6l-4.6,5.3c0,0-.6,0-1.8-.4-1.2-.3-2.2-.9-3.2-1.5-.9-.7-1.4-1.6-1.4-2.7v-4.3c0-.7,0-1.3-.2-1.8-.2-.4-.3-.8-.5-1-.2-.2-.3-.3-.3-.3l7-3.6Z" fill="url(#Orange_Yellow-2)" stroke-width="0"/>
  <path d="M69.2,32.9l-4.1,4.7c0,0-.3-.2-1-.7-.6-.5-1.2-1.2-1.7-2.1-.5-.9-.7-1.9-.7-3v-14c0-.4-.2-.8-.5-1s-.7-.3-1.2-.3-.9.1-1.2.4c-.3.2-.5.6-.5,1v15.5c.4.3.9.6,1.3.7l-2.8,3.5c0,0-.3-.2-1-.7-.6-.5-1.2-1.2-1.7-2.1-.5-.9-.7-1.9-.7-3v-11.7c0-1-.1-1.7-.3-2.3-.2-.5-.5-.9-.7-1.1-.3-.2-.4-.2-.4-.2l5.5-4.5v19c0,.3,0,.5,0,.8,0,.3.1.6.3.9v-16.5c0-.7.2-1.6.6-2.6.4-1.1,1.2-1.6,2.4-1.6h3.1c.1,0,.4,0,.7,0,.4,0,.8-.1,1.2-.3.4-.1.8-.3,1.1-.5,0,0-.2.3-.4.6-.2.3-.4.7-.6,1.3s-.3,1.1-.3,1.6v16.4c0,.5,0,1.1.3,1.8.2.7.7,1.1,1.5,1.2l1.3-1.5.4.3Z" fill="url(#Orange_Yellow-3)" stroke-width="0"/>
  <path d="M75.8,12.3l.2-.2h5c-.2.2-.5.7-1,1.5-.5.8-1.1,1.7-1.6,2.7-.5,1-.8,1.8-.9,2.5h-.4c0-.2.1-.7.4-1.5.3-.8.5-1.2.6-1.2h-4.1v12.2c0,1.7.8,3.1,2.3,4,1.5.9,2.9,1.5,4.2,1.8l1.3-1.5.4.3-4.1,4.7c0,0-.8-.3-2.3-1.2-1.5-.9-2.9-1.9-4.2-3-1.2-1.1-1.9-2.3-1.9-3.4v-8.1c0-.8-.1-1.5-.4-2-.2-.5-.5-.9-.9-1.1-.3-.2-.5-.3-.5-.3l7.8-6.1Z" fill="url(#Orange_Yellow-4)" stroke-width="0"/>
  <path d="M100,32.9l-4.1,4.7c-1.3-1-2.3-2-2.9-3.2s-1-2.2-1-3.1v-15.3h-2c-.4,0-.7.1-1,.4-.3.3-.4.6-.4,1v15.5c.4.3.7.6,1.1.9.3.2.6.4.6.5l-2.6,3.2c-2.2-1.9-3.6-3.4-4.4-4.5-.8-1.2-1.2-2.3-1.2-3.4V9.8c0-.3,0-.5,0-.6,0-.1-.2-.3-.4-.4-.2-.2-.3-.3-.4-.4.9-.4,2.1-1.2,3.6-2.3,1.5-1.1,2.8-2.2,4-3.4,1.2-1.2,1.9-2.1,2.1-2.8,0,.5-.2,1.1-.6,1.8-.4.7-1,1.6-1.8,2.6-.7.9-1.3,1.7-1.6,2.3-.4.6-.6,1.2-.6,1.6v20.3c0,1.2.4,2.4,1.3,3.5V14.1c0-.5.2-1,.7-1.4.5-.4,1-.6,1.6-.6h5.8c.1,0,.2,0,.3.1,0,0,.1.2.1.3v17.8c0,1.2.2,2.1.5,2.7.4.6.7,1,1.1,1.2l1.5-1.7.4.3Z" fill="url(#Orange_Yellow-5)" stroke-width="0"/>
</svg>
`;

const HanchLogoGradient = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: HanchLogoGradientSVG }} />
  );
};

export default HanchLogoGradient;
