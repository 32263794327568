import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth } from './firebase';
import { signInWithCustomToken, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { dbHanchUsers } from './firebase';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDevLogin, setShowDevLogin] = useState(false);
  const [isTester, setIsTester] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  const fetchUserData = async (uid) => {
    try {
      const userRef = doc(dbHanchUsers, 'users', uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data from Firestore:', userData);
        setIsTester(userData.isTester || false);
        setIsAdmin(userData.isAdmin || false);
      } else {
        console.log('No such document!');
        setIsTester(false);
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsTester(false);
      setIsAdmin(false);
    }
  };

  const checkSessionCookieAndAuth = useCallback(async () => {
    if (isLocalhost) {
      setLoading(false);
      return; // Skip for localhost
    }

    try {
      console.log('Calling /auth/check-status endpoint...');
      const response = await fetch('https://account.hanch.net/auth/check-status', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Check status endpoint result:', result);

        if (result.status === 'authenticated' && result.customToken) {
          console.log('Custom token received, signing in...');
          const userCredential = await signInWithCustomToken(auth, result.customToken);
          console.log('Sign in successful, user:', userCredential.user);
          setCurrentUser(userCredential.user);

          // Fetch user data from Firestore
          await fetchUserData(userCredential.user.uid);
        } else {
          console.log('User not authenticated');
          setCurrentUser(null);
          setIsTester(false);
          setIsAdmin(false);
        }
      } else if (response.status === 401) {
        console.log('Session expired or invalid');
        setCurrentUser(null);
        setIsTester(false);
        setIsAdmin(false);
        document.cookie = '__session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.hanch.net; secure; samesite=none';
      } else {
        console.log('Failed to check session status, response not ok');
      }
    } catch (error) {
      console.error('Error checking session cookie:', error);
      setCurrentUser(null);
      setIsTester(false);
      setIsAdmin(false);
    } finally {
      setLoading(false);
    }
  }, [isLocalhost]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isLocalhost) {
        setCurrentUser(user);
        if (user) {
          await fetchUserData(user.uid);
        }
        setLoading(false);
      } else {
        if (user) {
          setCurrentUser(user);
          await fetchUserData(user.uid);
          setLoading(false);
        } else {
          await checkSessionCookieAndAuth();
        }
      }
    });

    if (!isLocalhost) {
      checkSessionCookieAndAuth();
    } else {
      setLoading(false);
    }

    return () => unsubscribe();
  }, [isLocalhost, checkSessionCookieAndAuth]);

  const login = () => {
    if (isLocalhost) {
      setShowDevLogin(true);
    } else {
      console.log('Initiating login process...');
      const returnUrl = encodeURIComponent(window.location.href);
      const loginUrl = `https://account.hanch.net/login?returnUrl=${returnUrl}`;
      console.log('Redirecting to:', loginUrl);
      window.location.href = loginUrl;
    }
  };

  const logout = async () => {
    console.log('Initiating logout process...');
    if (!isLocalhost) {
      try {
        console.log('Calling /auth/logout endpoint...');
        const response = await fetch('https://account.hanch.net/auth/logout', {
          method: 'POST',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Server-side logout failed');
        }
        console.log('Server-side logout successful');
      } catch (error) {
        console.error('Error calling logout endpoint:', error);
      }
    }
    
    try {
      console.log('Signing out from Firebase...');
      await signOut(auth);
      console.log('Firebase sign out successful');
    } catch (error) {
      console.error('Error signing out from Firebase:', error);
    }

    if (!isLocalhost) {
      console.log('Clearing session cookie...');
      document.cookie = '__session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.hanch.net; secure; samesite=none';
    }

    console.log('Setting currentUser to null');
    setCurrentUser(null);
    setIsTester(false);
    setIsAdmin(false);
    console.log('Redirecting to home page...');
    window.location.href = '/';
  };

  const value = {
    currentUser,
    loading,
    login,
    logout,
    showDevLogin,
    setShowDevLogin,
    isLocalhost,
    isTester,
    isAdmin
  };

  useEffect(() => {
    if (!loading) {
      console.log('Auth state updated:', currentUser ? 'User logged in' : 'User logged out');
    }
  }, [loading, currentUser]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? <div className="fullpage-loading"><FontAwesomeIcon icon={faSpinner} spinPulse /></div> : children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
