import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase'; // Make sure this import points to your Firebase configuration
import { useAuth } from './AuthContext';

const DevLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setShowDevLogin } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setShowDevLogin(false); // Close the login modal on success
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="dev-login-overlay">
      <div className="dev-login-modal">
        <div>Local Development Login</div>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <div className="row">
          <button type="button" onClick={() => setShowDevLogin(false)}>Cancel</button>
          <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DevLogin;