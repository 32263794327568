import React, { useState, useEffect, useMemo, useRef } from 'react';

const Marquee = () => {
  const [alternateColors, setAlternateColors] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [speed, setSpeed] = useState(200);
  const containerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setAlternateColors(prev => !prev);
    }, speed);
    return () => clearInterval(interval);
  }, [speed]);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const height = width * (2 / 5);
        setSize({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateSize(); // Initial size update

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => setSpeed(100);
    const handleMouseLeave = () => setSpeed(200);

    const marqueeContainer = document.getElementById('marquee-container');
    if (marqueeContainer) {
      marqueeContainer.addEventListener('mouseenter', handleMouseEnter);
      marqueeContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (marqueeContainer) {
        marqueeContainer.removeEventListener('mouseenter', handleMouseEnter);
        marqueeContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  const svgContent = useMemo(() => {
    const topBottomLights = 27;
    const sideLights = 11;
    const dotColor = "#ffd900";
    const alternateColor = "#746520";

    const totalUnits = topBottomLights * 1.5 - 0.5;
    const unitSize = size.width / totalUnits;
    const halfUnitSize = unitSize / 2;
    const dotDiameter = unitSize;

    const dotRadius = dotDiameter / 2;
    const spacing = dotRadius;

    const totalHeight = (dotDiameter + spacing) * sideLights - spacing;

    let dots = [];

    const addDot = (x, y, index, flip) => {
      const effectiveIndex = flip ? index + 1 : index;
      const currentColor = (effectiveIndex % 2 === 0) !== alternateColors ? dotColor : alternateColor;
      dots.push(<circle key={`dot-${index}`} cx={x} cy={y} r={dotRadius} fill={currentColor} />);
    };

    for (let i = 0; i < topBottomLights; i++) {
      const x = dotRadius + i * (dotDiameter + spacing);
      addDot(x, dotRadius, i, false);
    }

    for (let i = 0; i < sideLights; i++) {
      const y = dotRadius + i * (dotDiameter + spacing);
      addDot(size.width - dotRadius, y, topBottomLights + i, true);
    }

    for (let i = 0; i < topBottomLights; i++) {
      const x = size.width - dotRadius - i * (dotDiameter + spacing);
      addDot(x, totalHeight - dotRadius, 2 * topBottomLights + sideLights - i - 1, false);
    }

    for (let i = 0; i < sideLights; i++) {
      const y = totalHeight - dotRadius - i * (dotDiameter + spacing);
      addDot(dotRadius, y, 2 * topBottomLights + 2 * sideLights - i - 1, true);
    }

    return { dots, totalHeight, unitSize, halfUnitSize };
  }, [size, alternateColors]);

  const containerStyle = {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  };

  const marqueeContainerStyle = {
    position: 'relative',
    width: '100%',
    height: `${svgContent.totalHeight}px`,
    maxWidth: '100%',
    zIndex: '1',
  };

  const contentStyle = {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    padding: `calc(${svgContent.unitSize}px)`,
    fontFamily: 'Arial Narrow, Arial, sans-serif',
  };

  const dateStyle = {
    display: 'inline-block',
    lineHeight: `calc(${svgContent.unitSize * 2.75}px)`,
    fontSize: `calc(${svgContent.unitSize * 2.75}px)`,
    fontWeight: 'bold',
  };

  const titleStyle = {
    display: 'inline-block',
    lineHeight: `calc(${svgContent.unitSize * 2.75}px)`,
    fontSize: `calc(${svgContent.unitSize * 2.75}px)`,
    fontWeight: 'bold',
    color: '#ffd900',
  };

  const locationStyle = {
    position: 'relative',
    fontSize: `calc(${svgContent.unitSize * 1.5}px)`,
    marginBottom: `calc(${svgContent.unitSize * 0.125}px)`,
    width: '100%',
  };

  return (
    <div ref={containerRef} style={containerStyle} className="marquee">
      <div id="marquee-container" style={marqueeContainerStyle}>
        <svg width="100%" height={svgContent.totalHeight} xmlns="http://www.w3.org/2000/svg">
          {svgContent.dots}
        </svg>
        <div style={contentStyle}>
          <div style={dateStyle}>DAY • MON ##, 'YR • ##:##PM</div>
          <div style={titleStyle}>ACT NAME</div>
          <div style={locationStyle}>
            <span style={{ fontWeight: 'bold' }}>CITY NAME, ST</span> • <span style={{ color: '#ffd900', fontWeight: 'bold' }}>VENUE/EVENT NAME</span>
            <div style={{ position: 'absolute', top: 0, right: `calc(${svgContent.unitSize * 1.5}px)` }}>ADM</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Marquee;
